<template>
  <Dialog v-model:visible="isShow" :style="{width: '450px'}" header="业务状态设置" :modal="true" class="p-fluid"
          @hide="isShow=false">
    <div class="p-field p-grid">
      <label class="p-col-fixed">
        请选择要设置的状态：
      </label>
      <div class="p-col">
        <RadioButton style="border-color: #12A25D" id="Working" value="Working" v-model="currStatus" />
        <label for="Working" style="margin-left: 10px;">工作中</label>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <RadioButton id="NonWorking" value="NonWorking" v-model="currStatus" />
        <label for="NonWorking" style="margin-left: 10px;">非工作</label>
      </div>
    </div>
    <div>
      <small class="p-error" v-if="!currStatus && isSubmited">请选择工作状态</small>
    </div>
    <template #footer>
      <Button style="color: #12A25D;border-color: #12A25D" label="取消" @click="isShow=false" class="p-button-outlined"/>
      <Button style="border-color: #12A25D;background: #12A25D" @click="submit()" label="确定"/>
    </template>
  </Dialog>
</template>

<script>
export default {
    data(){
      return {
        isShow: false,
        currRow : null,
        currStatus: null,
        isSubmited: false,
        parentObj: null
      }
    },
    mounted() {
    },
    methods: {
      show(row,parentObj) {
        this.parentObj = parentObj;
        this.isShow = true;
        this.currRow = row;

        // 数据库中虽包含了多个状态，但程序中仅显示为工作中和非工作两种状态
        // 因此这里需要判定，如果非Working，那就是NonWorking
        this.currStatus = row.bizDstatus == "Working" ? "Working" : "NonWorking";
      },
      submit(){
        let me = this;
        this.isSubmited = true;
        if(this.currStatus){

          // 不能直接修改currRow，否则会出现引用传递从而改变表格的值
          let temp = {};
          Object.assign(temp, this.currRow);
          temp.bizDstatus = this.currStatus;

          this.$confirm.require({
            header: '确认',
            message: '确定要变更状态吗？',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: '确认',
            rejectLabel: '取消',
            accept: () => {
              me.$http("/device/updateStatus","post",temp,()=>{
                me.isShow = false;
                me.$toast.add({severity:'success', summary: '成功', detail:'状态设置成功！', life: 3000});
                me.parentObj.loadTable(me.parentObj.dataTable.lastParams);
              });
            }
          });
        }
      }
    }
}
</script>
<style lang="scss" scoped>
::v-deep(.p-radiobutton .p-radiobutton-box.p-highlight){
  background: #12A25D;
  border-color: #12A25D;
}
::v-deep(.p-radiobutton .p-radiobutton-box.p-highlight:hover){
  background: #12A25D;
  border-color: #12A25D;
}
::v-deep(.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover){
  border-color: #12A25D;
}


</style>
