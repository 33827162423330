<template>
  <Breadcrumb :home="breadcrumb.home" :model="breadcrumb.items"
              style="padding-top:0px;background: none;border: none;"/>
  <div class="card">
    <div class="p-grid" style="margin:0">
      <div class="p-col-4">
        <label style="width:80px;display: inline-block;text-align: right">设备名称：</label>
        <InputText @keypress="$event.keyCode == 13 ? query() : {}" style="width:240px;"
                   v-model="dataTable.queryParams.dname" placeholder="请输入"/>
      </div>
      <div class="p-col-4">
        <label style="width:80px;display: inline-block;text-align: right">设备编号：</label>
        <InputText @keypress="$event.keyCode == 13 ? query() : {}" style="width:240px;"
                   v-model="dataTable.queryParams.fullid" placeholder="请输入"/>
      </div>
      <div class="p-col-4" v-if="hide">
        <label style="width:80px;display: inline-block;text-align: right">地点：</label>
        <InputText @keypress="$event.keyCode == 13 ? query() : {}" style="width:240px;"
                   v-model="dataTable.queryParams.fulladdr" placeholder="请输入"/>
      </div>
      <div class="p-col-4" v-if="hide">
        <label style="width:80px;display: inline-block;text-align: right">项目类型：</label>
        <Dropdown @keypress="$event.keyCode == 13 ? query() : {}" :filter="true" style="width:240px;"
                  v-model="dataTable.queryParams.projectType" placeholder="请选择" :options="projectType"
                  optionLabel="name"/>
      </div>
      <div class="p-col" style="display: flex;justify-content: flex-end;padding-right: calc(33.33% - 330px)">
        <Button style="height:33px;border-color: #d9d9d9;" label="重置" @click="reset()"
                class="btn p-button-outlined p-button-secondary p-mr-2"/>
        <Button style="border-color: #12A25D; background: #12A25D;height:33px" label="查询" class="btn p-mr-2"
                @click="query()"/>
        <Button style="height:33px;color: #12A25D;border-color: #FFFFFF" :label="hideTitle" @click="hideSelection()"
                :icon="'btn pi ' + (hide ? 'pi-chevron-up' : 'pi-chevron-down')" iconPos="right" class="p-button-link"/>
      </div>
    </div>
  </div>

  <div class="card">
    <Splitter class="p-mb-5" style="border:none;">
      <SplitterPanel :size="28" class="p-d-flex p-col-12">
        <div class="p-col-12">
          <div class="p-col-12">
            <span class="p-input-icon-right p-col-12" v-tooltip="'按下回车键搜索'">
              <i class="pi pi-search"/>
              <InputText @keypress="filterTree($event)" v-model="treeSearchWords" style="width:100%;" type="text"
                         placeholder="请输入"/>
            </span>
          </div>
          <div>
            <Tree @node-select="onNodeSelect" :expanded-keys="expandedKeys" style="width:100%;border:none;"
                  :value="nodes" selectionMode="single" v-model:selectionKeys="selectedKey1"></Tree>
          </div>
        </div>
      </SplitterPanel>
      <SplitterPanel style="width:10%;" :size="80" class="p-d-flex p-ai-center p-jc-center">

        <div class="p-grid" style="margin-top: 10px;width:100%;">
          <div class="p-col-12">
            <Button v-permission="['66','device001','fpdxm']" style="background: #12A25D;border-color: #12A25D"
                    :disabled="currNode.data != '-2'" label="分配到项目" class="p-mr-2" @click="configProject()"/>
            <Button v-permission="['66','device001','zbsz']" style="border-color: #d9d9d9" label="坐标设置"
                    class="p-button-outlined p-button-secondary p-mr-2" @click="configPosi()"/>
            <Button v-permission="['66','device001','tcsz']" style="border-color: #d9d9d9" label="套餐设置"
                    class="p-button-outlined p-button-secondary p-mr-2" @click="serviceConfig()"/>
            <Button v-permission="['66','device001','tjsb']" style="border-color: #d9d9d9" label="添加视频设备"
                    class="p-button-outlined p-button-secondary p-mr-2" @click="createNew()"/>
            <Button v-permission="['66','device001','tjsb']" style="border-color: #d9d9d9" label="添加设备"
                    class="p-button-outlined p-button-secondary p-mr-2" @click="addDevice"/>
          </div>
          <DataTable :scrollable="true" style="width:100%;" v-model:selection="dataTable.selected" scrollHeight="400px"
                     responsiveLayout="scroll" :rowHover="true" class="p-col-12" :value="dataTable.gridData"
                     :lazy="true"
                     @page="onPage($event)" :totalRecords="dataTable.totalRecords"
                     :paginator="true"
                     :rows="10"
                     paginatorTemplate="PrevPageLink PageLinks NextPageLink RowsPerPageDropdown CurrentPageReport"
                     :rowsPerPageOptions="[10, 20, 50, 100]" currentPageReportTemplate="共 {totalRecords} 条">
            <template #empty>
              没有发现数据。
            </template>
            <Column selectionMode="multiple" style="min-width:4rem"></Column>
            <Column field="fullid" header="设备编号" style="min-width:14rem"></Column>
            <Column field="dname" header="设备名称" style="min-width:10rem"></Column>
            <Column field="dtype" header="设备类型" style="min-width:8rem"></Column>
            <Column field="fulladdr" header="地点" style="min-width:18rem"></Column>
            <Column field="fullposi" header="坐标" style="min-width:11rem">
              <template #body="slotProps">
                <div v-if="slotProps.data.lonlatSource == 0" v-tooltip="'设备获取'"
                     style="cursor:pointer;padding:4px;border: 1px solid #B7EB8F;background: #F6FFED;border-radius: 50px;margin-right:4px;transform: scale(0.6);">
                  <i class="iconfont icon-dingwei" style="color:rgba(82, 196, 26, 1);"></i>
                </div>
                {{ slotProps.data.fullposi }}
              </template>
            </Column>
            <Column field="projectName" header="项目名称" style="min-width:16rem"></Column>
            <Column field="projectType" header="项目类型" style="min-width:12rem"></Column>
            <Column field="dstatus" header="设备状态" style="min-width:6rem"></Column>
            <Column field="bizDstatus" header="业务状态" style="min-width:6rem">
              <template #body="slotProps">
                <span v-if="slotProps.data.bizDstatus == 'Working'">
                  <div
                    style="float:left;margin-top:6px;margin-right:6px;width:8px;height:8px;border:1px solid rgba(82, 196, 26, 1);border-radius: 50px;background-color: rgba(82, 196, 26, 1);">
                  </div>
                  工作中
                </span>
                <span v-if="slotProps.data.bizDstatus == 'NonWorking'">
                  <div
                    style="float:left;margin-top:6px;margin-right:6px;width:8px;height:8px;border:1px solid rgba(187, 187, 187, 1);border-radius: 50px;background-color: rgba(187, 187, 187, 1);">
                  </div>
                  非工作
                </span>
              </template>
            </Column>
            <Column frozen alignFrozen="right" :exportable="false" header="操作" style="min-width:20rem">
              <template #body="slotProps">
                <a style="padding-right:8px;border-right:1px solid rgba(216, 216, 216, 1);margin:0" href="javascript:"
                   class="a-button" @click="createNew(slotProps.data)">编辑</a>
                <a href="javascript:" class="a-button" @click="detail(slotProps.data)">详细</a>
                <a v-permission="['66','device001','ywztsz']"  href="javascript:" class="a-button"
                   @click="configStatus(slotProps.data)">业务状态设置</a>
                <a v-if="slotProps.data.projectId != null"
                   v-permission="['66','device001','sbyc']"
                   style="padding-left:8px;padding-right:8px;border-left:1px solid rgba(216, 216, 216, 1);margin:0"
                   href="javascript:" class="a-button" @click="removeDev(slotProps.data)">移除</a>
              </template>
            </Column>
            <template #paginatorLeft></template>
            <template #paginatorRight>
              <div class="p-field">
                <label>跳至&nbsp;&nbsp;</label>
                <InputNumber @keypress="jumpPage()" v-tooltip.focus="'按下回车键后跳转'" inputStyle="width: 40px;"
                             id="integeronly" v-model="dataTable.jumpPageNum"/>
                <label>&nbsp;&nbsp;页</label>
              </div>
            </template>
          </DataTable>
        </div>

      </SplitterPanel>
    </Splitter>

    <ProjectConig ref="projectconfig"></ProjectConig>
    <StatusConfig ref="statusConfig"></StatusConfig>
    <PosiConfig ref="posiConfig"></PosiConfig>
    <ServiceConfig ref="serviceConfig"></ServiceConfig>
    <Detail ref="detail"></Detail>
    <OtherDeviceDetail ref="otherDetail" v-if="otherDetailDisplay" @close="otherDetailDisplay=false"></OtherDeviceDetail>
    <Toast/>

  </div>
</template>

<script>
import { email, helpers, required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import ProjectConig from "./ProjectConig";
import StatusConfig from "./StatusConfig";
import PosiConfig from "./PosiConfig";
import ServiceConfig from "./ServiceConfig";
import Detail from "./Detail";
import OtherDeviceDetail from '@/views/modules/device/OtherDeviceDetail.vue';

export default {
  components: { OtherDeviceDetail, ProjectConig, StatusConfig, PosiConfig, ServiceConfig, Detail },
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      otherDetailDisplay: false,
      hide: false,
      hideTitle: "展开",
      selectedKey1: null,
      leftProjectList: [],
      treeSearchWords: null,
      currNode: {}, // 当前选择的左侧类型，由于默认要加载因此不能是null
      nodes: [
        {
          key: "0",
          label: "全部",
          data: "-1",
          children: [],
        },
      ],
      expandedKeys: {},
      form: {
        id: null,
        name: null,
        telephone: null,
        role: null,
        email: null,
        team: null,
        $submitted: false,
        $formMode: "create",
      },
      dialog: {
        show: false,
        title: "",
        submitButtonText: "",
      },
      breadcrumb: {
        home: { label: "智能设备管理", to: "#" },
        items: [{ label: "设备分配", to: "#" }],
      },
      dataTable: {
        gridData: [],
        selected: null,
        queryParams: {
          dname: null,
          fullid: null,
          fulladdr: null,
          projectType: null,
        },
        lazyParams: {
          page: 0,
        },
        loading: true,
        totalRecords: 0,
        jumpPageNum: 1,
        lastParams: null,
      },
      resetPasswordDialogShow: false,
      resetPasswordRow: {},
      projectType: [
        { name: "科研/防风固沙", code: "科研/防风固沙" },
        { name: "科研/根系越冬", code: "科研/根系越冬" },
        { name: "生产/育种", code: "生产/育种" },
        { name: "生产/生产田间管理", code: "生产/生产田间管理" },
        { name: "生产/猪饲料-发酵床", code: "生产/猪饲料-发酵床" },
        { name: "生产/种菇", code: "生产/种菇" },
      ],

    };
  },
  mounted() {

    this.loadTable();
    this.loadProjectList();
  },
  validations() {
    return {
      form: {
        name: {
          required,
        },
        email: {
          email,
        },
        telephone: {
          required,
          format: helpers.regex(/^1\d{10}$/),
        },
        role: { required },
        team: {},
      },
    };
  },
  methods: {
    removeDev(row) {
      this.$confirm.require({
        header: "确认",
        message: "确定要移除 " + row.dname + " 吗？",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "确认",
        rejectLabel: "取消",
        accept: () => {
          this.$axios
            .get("/proj_dev_ref/removeDev", {
              params: {
                projectId: row.projectId,
                deviceId: row.pid + "-" + row.did,
              },
            })
            .then(() => {
              this.loadProjectList();
              this.loadTable({
                projectId: this.currNode.data,
              });
            });
        },
      });
    },
    detail(row) {
      if (row.pid === "DF" || row.pid === "XD") {
        this.otherDetailDisplay = true
        this.$nextTick(() => {
          this.$refs.otherDetail.getDetail(row)
        })
      } else {
        this.$refs.detail.show(row);
      }
    },
    serviceConfig() {
      if (!this.dataTable.selected || this.dataTable.selected.length == 0) {
        this.$toast.add({
          severity: "warn",
          summary: "错误",
          detail: "请选择一个设备！",
          life: 3000,
        });
        return;
      }
      this.$refs.serviceConfig.show(this.dataTable.selected, this);
    },
    filterTree(evt) {
      // 13 = enter key code
      if (evt.keyCode == 13) {
        this.loadProjectList();
      }
    },
    hideSelection() {
      if (!this.hide) {
        this.hideTitle = "收起";
        this.hide = true;
      } else {
        this.hideTitle = "展开";
        this.hide = false;
      }
    },
    addDevice() {
      this.$router.push("/device/add")
    },

    createNew(data) {
      if (data) {
        if (data.pid === "DF" || data.pid === "XD") {
          this.$router.push({ path: "/device/add", query: { pid: data.pid, did: data.did } });
        } else {
          this.$router.push({ path: "/device/form", query: { pid: data.pid, did: data.did } });
        }
      } else {
        this.$router.push("/device/form");
      }
    },

    configPosi() {
      if (!this.dataTable.selected || this.dataTable.selected.length == 0) {
        this.$toast.add({
          severity: "warn",
          summary: "错误",
          detail: "请选择一个设备！",
          life: 3000,
        });
        return;
      }
      // 不允许修改设备上传的坐标
      let next = true;
      for (let i in this.dataTable.selected) {
        let curr = this.dataTable.selected[i];
        if (curr.lonlatSource == 0) {
          next = false;
          break;
        }
      }
      if (!next) {
        this.$toast.add({
          severity: "warn",
          summary: "错误",
          detail: "所选设备中包含设备上传的坐标，不可修改！",
          life: 3000,
        });
        return;
      }
      this.$refs.posiConfig.show(this.dataTable.selected, this);
    },

    configStatus(row) {
      this.$refs.statusConfig.show(row, this);
    },

    onNodeSelect(node) {
      this.currNode = node;
      this.loadTable({
        projectId: node.data,
      });
    },

    configProject() {
      if (!this.dataTable.selected || this.dataTable.selected.length == 0) {
        this.$toast.add({
          severity: "warn",
          summary: "错误",
          detail: "请至少选择一个设备！",
          life: 3000,
        });
        return;
      }
      this.$refs.projectconfig.show(
        this.dataTable.selected,
        this.loadProjectList,
        {
          loadTable: this.loadTable,
          lastParams: this.dataTable.lastParams,
        }
      );
    },

    loadProjectList() {
      let me = this;
      this.$http(
        "/project/listHasDevs",
        "get",
        { projName: this.treeSearchWords },
        function(response) {
          me.nodes[0]["children"] = [];
          me.nodes[0].children.push({
            key: "0-0",
            label: "未分配",
            data: "-2",
            icon: "iconfont icon-daifenpei",
          });
          for (let i in response.data) {
            let item = response.data[i];
            // 之所有+1是因为第一个是"未分配"
            me.nodes[0]["children"].push({
              key: "0-" + (i + 1),
              label: item.name,
              data: item.id,
            });
          }
          for (let node of me.nodes) {
            me.expandNode(node);
          }
          me.expandedKeys = { ...me.expandedKeys };
        }
      );
    },
    expandNode(node) {
      this.expandedKeys[node.key] = true;
      if (node.children && node.children.length) {
        for (let child of node.children) {
          this.expandNode(child);
        }
      }
    },

    resetPassword(row) {
      this.$refs.resetPasswordDialog.show(row);
    },

    jumpPage() {
      if (this.dataTable.jumpPageNum > 0 && event.keyCode == 13) {
        this.dataTable.lazyParams.page = this.dataTable.jumpPageNum - 1;
        this.loadTable(this.dataTable.lastParams);
      }
    },

    query() {
      let params = {};
      Object.assign(params, this.dataTable.queryParams);
      // 只给服务器传项目类型的code字段
      let type = params.projectType;
      if (type) {
        params.projectType = type.code;
      }
      this.loadTable(params);
    },

    reset() {
      for (let i in this.dataTable.queryParams) {
        this.dataTable.queryParams[i] = null;
      }
      this.loadTable();
    },

    /**
     * 重置表单
     */
    resetForm() {
      for (let i in this.form) {
        this.form[i] = null;
      }
    },

    /**
     * 加载表格数据
     */
    loadTable(params) {
      let me = this;
      me.loading = true;
      this.dataTable.lastParams = params;
      let targetParmas = {
        current: this.dataTable.lazyParams.page + 1,
        size: this.dataTable.lazyParams.rows,
      };
      if (params) {
        Object.assign(targetParmas, params);
      }
      this.$http("/device/list", "get", targetParmas, function(response) {
        me.dataTable.gridData = response.data.records;
        me.dataTable.totalRecords = response.data.total;
        me.dataTable.loading = false;
        me.dataTable.selected = null;
      });
    },

    onPage(event) {
      this.dataTable.lazyParams = event;
      this.loadTable(this.dataTable.lastParams);
    },

    /**
     * 隐藏对话框
     */
    hideDialog() {
      this.dialog.show = false;
      this.resetForm();
    },

    /**
     * 以创建模式打开对话框
     */
    showDialogAsCreate() {
      this.form.$formMode = "create";
      this.resetForm();
      this.dialog = {
        show: true,
        title: "添加用户",
        submitButtonText: "创建",
      };
    },

    /**
     * 以编辑模式打开对话框
     * @param row
     */
    showDialogAsEdit(row) {
      let me = this;
      this.form.$formMode = "edit";
      this.resetForm();
      this.dialog = {
        show: true,
        title: "编辑用户",
        submitButtonText: "保存",
      };
      this.$http("/user/get", "get", { id: row.id }, function(response) {
        me.form = response.data;
      });
    },

    /**
     * 删除记录
     * @param row
     */
    remove(row) {
      let me = this;
      this.$confirm.require({
        header: "确认",
        message: "确定要删除此用户吗？",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "确认",
        rejectLabel: "取消",
        accept: () => {
          me.$http("/user/remove", "get", { ids: row.id }, function() {
            me.$toast.add({
              severity: "success",
              summary: "成功",
              detail: "用户删除成功！",
              life: 3000,
            });
            me.loadTable();
          });
        },
      });
    },

    /**
     * 提交表单
     * @param isInvalid
     */
    submit(isInvalid) {
      let me = this;
      this.form.$submitted = true;
      if (isInvalid) {
        return;
      }

      this.$confirm.require({
        message: "确认要执行此操作吗？",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "确认",
        rejectLabel: "取消",
        accept: () => {
          this.$http("/user/saveOrUpdate", "get", this.form, function() {
            me.$toast.add({
              severity: "success",
              summary: "成功",
              detail: "用户添加成功！",
              life: 3000,
            });
            me.hideDialog();
            me.loadTable();
          });
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.a-button {
  color: #12a25d;
  font-size: 14px;
  margin-left: 8px;
  margin-right: 8px;
}

.select1 {
  display: flex;
  justify-content: space-between;
}

.admin-status {
  width: 56px;
  height: 22px;
  border: 1px double #91d5ff;
  color: #1890ff;
  font-size: 12px;
  padding: 4px 8px 4px 8px;
  background-color: #e6f7ff;
}

.user-status {
  width: 56px;
  height: 22px;
  border: 1px double #b7eb8f;
  color: #52c41a;
  font-size: 12px;
  padding: 4px 8px 4px 8px;
  background-color: #f6ffed;
}

//去掉表格底部的线条
::v-deep(.p-paginator-bottom) {
  border-bottom: none !important;
}

.btn {
  height: 33px;
}

.p-input-icon-right > i:last-of-type {
  right: 1rem;
}

::v-deep(.p-checkbox .p-checkbox-box.p-highlight) {
  border-color: #12a25d;
  background: #12a25d;
}

::v-deep(.p-checkbox .p-checkbox-box.p-highlight:hover) {
  border-color: #12a25d;
  background: #12a25d;
}

::v-deep(.p-tree .p-tree-container .p-treenode) {
  padding: 0.143rem 0;
}

::v-deep(.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler) {
  margin-right: 0;
}

::v-deep(.p-tree .p-treenode-children) {
  padding: 0 0 0 0.5rem;
}

::v-deep(.p-checkbox .p-checkbox-box:hover) {
  border-color: #12a25d;
}

::v-deep(.p-paginator .p-paginator-pages .p-paginator-page.p-highlight) {
  background: #12a25d;
  border-color: #12a25d;
  color: #ffffff;
}
</style>
