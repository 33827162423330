<template>
  <Dialog id="mapSelect00" v-model:visible="dialog.show" :style="{width: '800px',height:'568px','padding':'0px','overflow':'hidden'}" :header="getTitle()" :modal="true" class="p-fluid"
          @hide="hideDialog">
    <div class="p-inputgroup" style="width: 240px;position: fixed;left: 50px;top:100px;">
      <InputText @keypress="$event.keyCode==13?(mapKeywords?searchMap():clearSearch()):{}" v-model.trim="mapKeywords" placeholder="请输入"/>
      <Button  style="background: #12A25D;border-color: #12A25D" @click="searchMap" icon="pi pi-search"/>
      <Button  style="background: #12A25D;border-color: #12A25D" v-if="mapKeywords" icon="pi pi-times" @click="clearSearch()" class="p-button-secondary"/>
    </div>
    <iframe style="width:100%;height:420px;border: none;" src="/amap.html"></iframe>
    <Toolbar style="background: none; border: none;">
      <template #left>
        <Button style="border-color: #12A25D;color: #12A25D" label="清除坐标" @click="clear()" class="p-mr-2 p-button-outlined" />
      </template>
      <template #right>
        <Button style="color: #12A25D;border-color: #12A25D" label="取消" @click="hideDialog()" class="p-mr-2 p-button-outlined" />
        <Button style="background: #12A25D;border-color: #12A25D" label="确定" @click="selected()" class="p-mr-2" />
      </template>
    </Toolbar>
  </Dialog>
</template>

<script>
export default {
    data(){
      return {
        dialog: {
          show : false
        },
        selectedPosition: null,
        mapKeywords: null,
        currSelectedDevice: null,
        parentObj: null
      }
    },
    mounted() {
      let me = this;
      top["setSelectedPosition"] = function (pos){
        me.selectedPosition = pos;
      }
    },
    methods: {
      searchMap(){
        top["searchMap"](this.mapKeywords);
      },
      clearSearch(){
        this.mapKeywords = null;
        top["clearSearch"]();
      },
      clear(){
        this.selectedPosition = null;
        this.mapKeywords = null;
        this.$toast.add({severity: 'success', summary: '消息', detail: '已清空所选位置！', life: 3000});
      },
      selected(){
        if(!this.selectedPosition){
          this.$toast.add({severity: 'warn', summary: '错误', detail: '请选择一个经纬度！', life: 3000});
          return
        }

        let fullIds = [];
        for(let i in this.currSelectedDevice){
          fullIds.push(this.currSelectedDevice[i].tableid)
        }

        let parmas = {
            target: fullIds,
            longtitude : this.selectedPosition.lon,
            latitude : this.selectedPosition.lat,
            province : this.selectedPosition.province,
            city : this.selectedPosition.city,
            district : this.selectedPosition.district,
            address : this.selectedPosition.address
        }

        this.$http("/device/updatePosi", "post", parmas, ()=>{
          this.$toast.add({severity: 'success', summary: '成功', detail: '地理信息更新成功！', life: 3000});
          this.dialog.show = false;
          this.parentObj.loadTable(this.parentObj.dataTable.lastParams)
        });

      },
      getTitle(){
        if(this.selectedPosition) {
          return '坐标位置 - ' + this.selectedPosition.province + this.selectedPosition.city + this.selectedPosition.district+(this.selectedPosition.address?this.selectedPosition.address:"")+"（"+this.selectedPosition.lon+","+this.selectedPosition.lat + '）';
        }else{
          return '坐标位置';
        }
      },
      hideDialog(){
        this.dialog.show = false;
      },
      show(rows,parentObj) {
        this.dialog.show = true;
        this.currSelectedDevice = rows;
        this.mapKeywords = null;
        this.parentObj = parentObj;
        this.selectedPosition = null;
      },
      submit(){
        // let me = this;
        this.isSubmited = true;
      }
    }
}
</script>
<style scoped>

</style>
