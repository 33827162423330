<template>
  <Dialog v-model:visible="isShow" :style="{width: '450px'}" header="分配到项目" :modal="true" class="p-fluid" @hide="isShow=false">
    <div class="p-field p-grid">
      <label for="currProject" class="p-col-fixed" style="width:100px">
        所属项目：
      </label>
      <div class="p-col">
        <Dropdown :filter="true" id="currProject" v-model="currProject" :options="projectList" optionLabel="name" placeholder="请选择" />
        <small class="p-error" v-if="isSubmited && !currProject">请选择项目</small>
      </div>
    </div>
    <template #footer>
      <div class="p-grid">
        <div v-if="currDevs&&currDevs.length>1" class="p-col-7" style="text-align: left;">
          多选模式下无法设置AI分析
        </div>
        <div v-else style="width:100%">
          <div v-if="hasWebCam()" class="p-col-7" style="display: flex;align-items: center">
            <Checkbox id="isAI" value='true' v-model="isAI" style="margin: 8px 0" />
            <label for="isAI" style="margin-left:4px;color:#12A25D;">摄像头加入大棚日照分析AI计算</label>
          </div>
          <div v-else class="p-col-7" style="text-align: left;">
            非摄像头设备
          </div>
        </div>
        <div style="width:100%;display:flex;justify-content:flex-end">
          <Button style="border-color: #12A25D;color: #12A25D" label="取消" @click="isShow=false" class="p-button-outlined" />
          <Button style="border-color: #12A25D;background: #12A25D" @click="submit()" label="确定" />
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script>
export default {
  data() {
    return {
      result: null,
      isShow: false,
      projectList: [],
      currProject: null,
      currDevs: [], // 默认使用，不能为null
      isAI: false,
      isSubmited: false,
      parentoadProjectList: null,
      parentLoadTable: null,
    };
  },
  mounted() {
    this.loadProjectList();
  },
  methods: {
    hasWebCam() {
      let result = null;
      for (let i in this.currDevs) {
        let dev = this.currDevs[i];
        if (dev.pid == "webcam") {
          result = true;
          this.result = true;
        } else if (dev.pid == "jf_webcam") {
          result = true;
          this.result = true;
        } else if (dev.pid == "tuya_webcam") {
          result = true;
          this.result = true;
        }
        else {
          this.result = false;
          result = false;
        }
      }
      return result;
    },
    show(devs, loadProjectList, loadTableObj) {
      this.isShow = true;
      this.currDevs = devs;
      if (this.currDevs > 0) {
        this.result = false;
      }
      this.parentoadProjectList = loadProjectList;
      this.parentLoadTable = loadTableObj;
    },
    submit() {
      let isAI = null;
      if (this.result) {
        if (this.isAI.length > 0) {
          isAI = "0";
        } else {
          isAI = "1";
        }
      } else {
        isAI = null;
      }
      let me = this;
      this.isSubmited = true;
      if (this.currProject) {
        let params = { projectId: this.currProject.id, isAi: isAI };
        let devstr = "";
        for (let i in this.currDevs) {
          let item = this.currDevs[i];
          devstr += item.tableid + ",";
        }
        devstr = devstr.substring(0, devstr.length - 1);
        params["deviceIds"] = devstr;
        this.$http("/proj_dev_ref/save", "get", params, function () {
          me.$toast.add({
            severity: "success",
            summary: "成功",
            detail: "已完成设备分配！",
            life: 3000,
          });
          me.parentoadProjectList();
          me.parentLoadTable.loadTable(me.parentLoadTable.lastParams);
          me.isShow = false;
        });
      }
    },
    loadProjectList() {
      let me = this;
      this.$http("/project/loadAllProjects", "get", {}, function (response) {
        me.projectList = response.data;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep(.p-checkbox .p-checkbox-box.p-highlight) {
  background-color: #12a25d;
  border-color: #12a25d;
}
</style>
