<template>
  <Dialog v-model:visible="display" header="设备详情" style="width: 500px" @close="$emit('close')">
    <div>
      <div class="p-field">
        <label>编号:</label>
        {{ form.pid + '-' + form.did }}
      </div>
      <div class="p-field">
        <label>名称:</label>
        {{ form.dname }}
      </div>
      <div class="p-field">
        <label>型号:</label>
        {{ form.series }}
      </div>
      <div class="p-field">
        <label>部署地点:</label>
        {{ form.province + form.city + form.district + form.address }}
      </div>
      <div class="p-field">
        <label>坐标:</label>
        {{ form.longtitude + ',' + form.latitude }}
      </div>
      <div class="p-field">
        <label>设备类型:</label>
        {{ form.deviceType === 0 ? '东方智感' : '矽递' }}
      </div>
      <div class="p-field" v-if="form.deviceType===0">
        <label>appId:</label>
        {{ form.appId }}
      </div>
      <div class="p-field" v-if="form.deviceType===0">
        <label>appSecret:</label>
        {{ form.appSecret }}
      </div>
      <div class="p-field" v-if="form.deviceType===0">
        <label>设备id:</label>
        {{ form.deviceId }}
      </div>

      <div class="p-field" v-if="form.deviceType===1">
        <label>地址:</label>
        {{ form.url }}
      </div>
      <div class="p-field" v-if="form.deviceType===1">
        <label>端口:</label>
        {{ form.port }}
      </div>
      <div class="p-field" v-if="form.deviceType===1">
        <label>用户名:</label>
        {{ form.username }}
      </div>
      <div class="p-field" v-if="form.deviceType===1">
        <label>密码:</label>
        {{ form.password }}
      </div>
      <div class="p-field" v-if="form.deviceType===1">
        <label>设备Eui:</label>
        {{ form.deviceEui}}
      </div>
    </div>
  </Dialog>
</template>

<script>
export default {
  name: 'OtherDeviceDetail',
  data() {
    return {
      display: false,
      form: {
        dname: null,
        ddescript: null,
        did: null,
        pid: null,
        longtitude: null,
        latitude: null,
        country: "china",
        province: null,
        city: null,
        address: null,
        district: null,
        deviceType: null,
        appId: null,
        appSecret: null,
        deviceId: null,
        url: null,
        port: null,
        username: null,
        password: null,
      },
    }
  },
  methods: {
    getDetail(data) {
      this.display = true;
      this.$http("/device/other/get", 'get', { pid: data.pid, did: data.did }, (res) => {
        this.form = res.data
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
