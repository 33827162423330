<template>
  <Dialog v-model:visible="isShow" :style="{width: '450px'}" header="设备详情" :modal="true" class="p-fluid" @hide="isShow=false">
    <form class="p-fluid" style="margin-top: 32px">
      <div class="p-field p-grid">
        <label class="p-col-fixed" style="width:150px;justify-content: flex-end">
          <span style="color:red;margin-right: 4px;"></span>编号:
        </label>
        <div class="p-col">
          {{data.detail.pid+'-'+data.detail.did}}
        </div>
      </div>
      <div class="p-field p-grid">
        <label class="p-col-fixed" style="width:150px;justify-content: flex-end">
          <span style="color:red;margin-right: 4px;"></span>名称:
        </label>
        <div class="p-col">
          {{data.detail.dname}}
        </div>
      </div>
      <div class="p-field p-grid">
        <label class="p-col-fixed" style="width:150px;justify-content: flex-end">
          <span style="color:red;margin-right: 4px;"></span>型号:
        </label>
        <div class="p-col">
          {{data.detail.series}}
        </div>
      </div>
      <div class="p-field p-grid">
        <label class="p-col-fixed" style="width:150px;justify-content: flex-end">
          <span style="margin-right: 4px;"></span>部署地点:
        </label>
        <div class="p-col">
          {{data.detail.province+data.detail.city+data.detail.district+data.detail.address}}
        </div>
      </div>
      <div class="p-field p-grid">
        <label class="p-col-fixed" style="width:150px;justify-content: flex-end">
          <span style="margin-right: 4px;"></span>坐标:
        </label>
        <div class="p-col">
          {{data.detail.longtitude+','+data.detail.latitude}}
        </div>
      </div>
      <div class="p-field p-grid">
        <label class="p-col-fixed" style="width:150px;justify-content: flex-end">
          <span style="margin-right: 4px;"></span>设备类型:
        </label>
        <div class="p-col">
          {{data.detail.dtype}}
        </div>
      </div>
      <div class="p-field p-grid">
        <label class="p-col-fixed" style="width:150px;justify-content: flex-end">
          <span style="margin-right: 4px;"></span>所属项目:
        </label>
        <div class="p-col">
          {{data.detail.projectName}}
        </div>
      </div>
      <div class="p-field p-grid">
        <label class="p-col-fixed" style="width:150px;justify-content: flex-end">
          <span style="margin-right: 4px;"></span>SIM运营商:
        </label>
        <div class="p-col">
          {{data.conf.isp_name}}
        </div>
      </div>
      <div class="p-field p-grid">
        <label class="p-col-fixed" style="width:150px;justify-content: flex-end">
          <span style="margin-right: 4px;"></span>SIM套餐期限:
        </label>
        <div class="p-col">
          {{data.conf.isp_valid_period}}
        </div>
      </div>
      <div class="p-field p-grid">
        <label class="p-col-fixed" style="width:150px;justify-content: flex-end">
          <span style="margin-right: 4px;"></span>SIM套餐备注:
        </label>
        <div class="p-col">
          {{data.conf.isp_comments}}
        </div>
      </div>
      <div v-if="row.pid === 'webcam'">
        <div class="p-field p-grid">
          <label class="p-col-fixed" style="width:150px;justify-content: flex-end">
            <span style="margin-right: 4px;"></span>设备序列号:
          </label>
          <div class="p-col">
            {{data.conf.serial_num}}
          </div>
        </div>
        <div class="p-field p-grid">
          <label class="p-col-fixed" style="width:150px;justify-content: flex-end">
            <span style="margin-right: 4px;"></span>AppKey:
          </label>
          <div class="p-col">
            {{data.conf.appKey}}
          </div>
        </div>
        <div class="p-field p-grid">
          <label class="p-col-fixed" style="width:150px;justify-content: flex-end">
            <span style="margin-right: 4px;"></span>AppSecret:
          </label>
          <div class="p-col">
            {{data.conf.appSecret}}
          </div>
        </div>
      </div>

      <div v-if="row.pid === 'tuya_webcam'">

        <div class="p-field p-grid">
          <label class="p-col-fixed" style="width:150px;justify-content: flex-end">
            <span style="margin-right: 4px;"></span>accessId:
          </label>
          <div class="p-col">
            {{data.conf.accessId}}
          </div>
        </div>
        <div class="p-field p-grid">
          <label class="p-col-fixed" style="width:150px;justify-content: flex-end">
            <span style="margin-right: 4px;"></span>accessKey:
          </label>
          <div class="p-col">
            {{data.conf.accessKey}}
          </div>
        </div>
      </div>
      <div v-if="row.pid === 'jf_webcam'">
        <div class="p-field p-grid">
          <label class="p-col-fixed" style="width:150px;justify-content: flex-end">
            <span style="margin-right: 4px;"></span>设备序列号:
          </label>
          <div class="p-col">
            {{data.conf.jf_serial_num}}
          </div>
        </div>
        <div class="p-field p-grid">
          <label class="p-col-fixed" style="width:150px;justify-content: flex-end">
            <span style="margin-right: 4px;"></span>账号:
          </label>
          <div class="p-col">
            {{data.conf.account}}
          </div>
        </div>
        <div class="p-field p-grid">
          <label class="p-col-fixed" style="width:150px;justify-content: flex-end">
            <span style="margin-right: 4px;"></span>密码:
          </label>
          <div class="p-col">
            {{data.conf.password}}
          </div>
        </div>
        <div class="p-field p-grid">
          <label class="p-col-fixed" style="width:150px;justify-content: flex-end">
            <span style="margin-right: 4px;"></span>Secret:
          </label>
          <div class="p-col">
            {{data.conf.secret}}
          </div>
        </div>
        <div class="p-field p-grid">
          <label class="p-col-fixed" style="width:150px;justify-content: flex-end">
            <span style="margin-right: 4px;"></span>ClientId:
          </label>
          <div class="p-col">
            {{data.conf.clientId}}
          </div>
        </div>
      </div>
      <div class="p-dialog-footer">
        <Button label="取消" class="p-button-outlined" @click="isShow=false" />
      </div>
    </form>
  </Dialog>
</template>

<script>
export default {
  data() {
    return {
      isShow: false,
      form: {
        isp: null,
        validPeriod: null,
        comments: null,
        $submitted: false,
      },
      row: null,
      // 不能是null，因为页面要使用
      data: {
        detail: {},
        conf: {},

      },

    };
  },
  methods: {
    show(row) {

      this.row = row;
      this.isShow = true;
      // 注意enableTime要转换格式，因为值是时间戳格式
      this.$http(
        "/device/getWithAllConf",
        "get",
        {
          pid: this.row.pid,
          did: this.row.did,
          strEnableTime: this.row.enableTime,
          projectId: this.row.projectId,
        },
        (resp) => {
          this.data = resp.data;
          for (let i in this.data.conf) {
            let item = this.data.conf[i];
            this.data.conf[item.name] = item.value;
          }
        }
      );
    },
  },
};
</script>
<style lang="scss" scoped>
label {
  color: #666666;
}
.p-dialog .p-dialog-footer {
  padding: 0;
}
::v-deep(.p-field) {
  margin-bottom: 1rem;
}
</style>
